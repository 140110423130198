import React, { Suspense, lazy } from "react";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "../../services/apollo";

const isMocked = process.env.REACT_APP_MOCKED === "true";

const UserManagementProvider = ({ children }) => {
  if (isMocked) {
    const LazyProvider = lazy(() =>
      import("../../mocks/MockedUserManagementProvider")
    );
    return (
      <Suspense fallback={<p>loading...</p>}>
        <LazyProvider>{children}</LazyProvider>
      </Suspense>
    );
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default UserManagementProvider;
