import React, { useState } from "react";

import { Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";

import UsersTable from "../UsersTable/UsersTable";
import ActionTitle from "./ActionTitle";
import RemoveConfirmationDialog from "./RemoveConfirmationDialog";

const NoUsersInGroup = () => (
  <Typography variant="h4" component="p" gutterBottom>
    No users found
  </Typography>
);

const Users = ({ users, groupName, onConfirmRemoveClick }) => {
  const [selection, setSelection] = useState([]);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);

  const showConfirmation = () => setConfirmationOpen(true);
  const hideConfirmation = () => setConfirmationOpen(false);
  const onSelectionChange = (selected) => setSelection(selected);

  const handleRemoveClick = () => {
    hideConfirmation();
    onConfirmRemoveClick(selection);
  };

  return (
    <>
      <ActionTitle
        groupName={groupName}
        disableRemoveUser={selection.length === 0}
        onRemoveClick={showConfirmation}
      />
      {isEmpty(users) ? (
        <NoUsersInGroup />
      ) : (
        <>
          <UsersTable users={users} onSelectionChange={onSelectionChange} />
          <RemoveConfirmationDialog
            open={isConfirmationOpen}
            count={selection.length}
            groupName={groupName}
            onClose={hideConfirmation}
            onConfirm={handleRemoveClick}
          />
        </>
      )}
    </>
  );
};

export default Users;
