import React from 'react';
import _ from 'lodash';
import ExtraControl from './ExtraControl';

const getExtraControls = (extraControls) =>
  // would normally use _.chain here, but need to keep lodash imports
  // to a minimum
  _.groupBy(
    _.map(extraControls, ({ side, ...control }, i) => ({
      side,
      button: <ExtraControl key={i} {...control} />,
    })),
    'side'
  );

export default getExtraControls;
