import React from "react";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar as Spacer,
} from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import { StyledLink } from "../../styles/styles";
import LogoutButton from "../LogoutButton/LogoutButton";

const drawerStyle = {
  width: "300px",
  flexShrink: 0,
  [`& .MuiDrawer-paper`]: { width: "300px" },
};

const boxStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  padding: "1rem",
};

const Container = ({ children }) => (
  <Drawer variant="permanent" sx={drawerStyle} role="navigation">
    <Spacer />
    <Box sx={boxStyle}>{children}</Box>
  </Drawer>
);

const GroupItem = () => (
  <StyledLink to="/">
    <ListItem button selected>
      <ListItemIcon>
        <GroupsIcon />
      </ListItemIcon>
      <ListItemText>Groups</ListItemText>
    </ListItem>
  </StyledLink>
);

const dividerStyle = { marginBottom: "1rem" };

const Sidebar = () => (
  <Container>
    <List>
      <GroupItem />
    </List>
    <Box>
      <Divider sx={dividerStyle} />
      <LogoutButton />
    </Box>
  </Container>
);

export default Sidebar;
