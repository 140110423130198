import { useQuery } from "@apollo/client";
import { USER_ACTIVITY } from "../queries";

const getLogsData = (data) => data?.logs.edges.map(({ node }) => node);

const getActivityData = (data) => {
  const user = data?.user;
  const logs = getLogsData(data);

  return {
    ...user,
    logs,
  };
};

const useUserActivity = (id) => {
  const { data, loading, error } = useQuery(USER_ACTIVITY, {
    variables: { id },
  });

  return { loading, error, activity: getActivityData(data) };
};

export default useUserActivity;
