import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { GroupsPage, GroupDetailPage } from "./pages";

const Routes = () => (
  <Switch>
    <Route exact path="/" component={GroupsPage} />
    <Route path="/group/:id" component={GroupDetailPage} />
    <Route render={() => <Redirect to="/" />} />
  </Switch>
);

export default Routes;
