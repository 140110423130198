import { gql } from "@apollo/client";

const USER_ACTIVITY = gql`
  query UserActivity($id: String!) {
    user(id: $id) {
      createTime
      activationTime
      lastSignInTime
    }

    logs(userId: $id, sortOrder: DESC) {
      edges {
        node {
          timestamp
          details
        }
      }
    }
  }
`;

export default USER_ACTIVITY;
