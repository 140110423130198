import { gql } from "@apollo/client";

const GET_USER_BY_EMAIL = gql`
  query GetUserByEmail($username: String!) {
    getUserByEmail(username: $username) {
      username
      email
      groups {
        sk
        createTime
        pk
        data
      }
      permissions
    }
  }
`;

export default GET_USER_BY_EMAIL;
