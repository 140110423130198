import { styled } from "@mui/material/styles";
import Box from "@mui/system/Box";
import { Link } from "react-router-dom";

import { transitionOut, transitionIn } from "./animations";

export const drawerWidth = 250;

export const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  backgroundColor: theme.palette.background.paper,
}));

export const StyledBoxMain = styled(Box, {
  shouldForwardProp: (prop) => prop !== "drawerIsOpen",
})(({ theme, drawerIsOpen }) => ({
  height: "100vh",
  transition: transitionOut(theme),
  marginLeft: -drawerWidth,
  ...(drawerIsOpen && {
    transition: transitionIn(theme),
    marginLeft: 0,
  }),
}));

export const StyledDrawerHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export const StyledLink = styled(Link)(() => ({
  color: "inherit",
  textDecoration: "inherit",
}));
