import React from "react";
import { Box } from "@mui/material";
import Sidebar from "./Sidebar";
import TopToolBar from "./TopToolBar";

const mainStyle = { flexGrow: 1, p: 3 };
const appStyle = { display: "flex" };

const Layout = ({ children }) => (
  <Box sx={appStyle}>
    <TopToolBar />

    <Sidebar />

    <Box component="main" sx={mainStyle}>
      {children}
    </Box>
  </Box>
);

export default Layout;
