import React from "react";
import { Loading } from "../../components";
import Users from "./Users/Users";

import { useUsers } from "../../hooks";

const GroupDetailPage = ({ match }) => {
  const { id } = match.params;
  const { users, loading, error } = useUsers(id);

  if (loading) return <Loading label="group detail" />;
  if (error) return `Error! ${error.message}`;

  const removeUsers = (userIds) => {
    // TODO: call mutation
    // eslint-disable-next-line no-console
    console.log("[removeUsers] userIds:", userIds);
  };

  return (
    <Users users={users} groupName={id} onConfirmRemoveClick={removeUsers} />
  );
};

export default GroupDetailPage;
