import React from "react";
import { Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useUserActivity } from "../../../hooks";
import { Loading } from "../../../components";

const formatDate = (date) => date.split("T")[0];

const dlStyle = { display: "flex", flexDirection: "row" };

const DateValue = ({ label, date }) => (
  <Grid item xs={4}>
    <dl style={dlStyle}>
      <Typography component="dt">{label}:</Typography>
      <Typography component="dd" marginLeft="1rem" fontWeight={600}>
        {formatDate(date)}
      </Typography>
    </dl>
  </Grid>
);

const ActivityDates = ({
  activity: { createTime, activationTime, lastSignInTime },
}) => (
  <Grid container spacing={2} data-testid="activity-dates" marginBottom="1rem">
    <DateValue label="Created" date={createTime} />
    <DateValue label="Activated" date={activationTime} />
    <DateValue label="Last sign-in" date={lastSignInTime} />
  </Grid>
);

const listStyle = {
  overflow: "auto",
  maxHeight: 300,
};

const ActivityLog = ({ logs }) => (
  <>
    <Typography component="h3" variant="h6">
      Activity:
    </Typography>
    <List sx={listStyle}>
      {logs.map((log) => (
        <ListItem key={log.details}>
          <ListItemText primary={`${log.timestamp} - ${log.details}`} />
        </ListItem>
      ))}
    </List>
  </>
);

const ActivityDetails = ({ user }) => {
  const { loading, error, activity } = useUserActivity(user.id);

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  return (
    <>
      <ActivityDates activity={activity} />
      <ActivityLog logs={activity.logs} />
    </>
  );
};

export default ActivityDetails;
