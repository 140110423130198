import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import formatSeconds from '../../../utils/formatting';

const durationPlaceholder = '--:-- / --:--';

const TimeIndicator = React.memo(({ position, duration }) => {
  const durationString = duration
    ? `${formatSeconds(Math.ceil(position))} / ${formatSeconds(
        Math.ceil(duration)
      )}`
    : durationPlaceholder;

  return (
    <Typography variant="overline" align="center" display="block" data-testid="time-indicator">
      {durationString}
    </Typography>
  );
});

TimeIndicator.propTypes = {
  position: PropTypes.number,
  duration: PropTypes.number,
};

TimeIndicator.defaultProps = {
  position: 0,
  duration: 0,
};

export default TimeIndicator;
