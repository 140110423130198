import { gql } from "@apollo/client";

const USERS = gql`
  query Users($groupId: String!, $first: Int, $after: String) {
    users(groupId: $groupId, first: $first, after: $after) {
      edges {
        node {
          id
          email
        }
      }
    }
  }
`;

export default USERS;
