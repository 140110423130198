import React, { useState } from "react";
import { Button } from "@mui/material";
import { LicenseInfo, DataGridPro } from "@mui/x-data-grid-pro";
import UserActivityDialog from "../UserActivityDialog/UserActivityDialog";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);

const ViewActivityButton = ({ label, onClick }) => (
  <Button type="button" color="primary" size="small" onClick={onClick}>
    {label}
  </Button>
);

const makeColumn = (onClick) => [
  { field: "email", headerName: "User Email", flex: 1 },
  {
    field: "action",
    headerName: "",
    align: "center",
    minWidth: 200,
    renderCell: ({ formattedValue, id }) => (
      <ViewActivityButton label={formattedValue} onClick={() => onClick(id)} />
    ),
  },
];

const UsersTable = ({ users, onSelectionChange }) => {
  const [userActivity, setUserActivity] = useState(null);
  const hideUserActivity = () => setUserActivity(null);
  const showUserActivity = (userId) => {
    const user = users.find((u) => u.id === userId);
    setUserActivity(user);
  };

  return (
    <>
      <DataGridPro
        columns={makeColumn(showUserActivity)}
        rows={users.map((user) => ({ ...user, action: "View user activity" }))}
        checkboxSelection
        disableSelectionOnClick
        getRowId={(row) => row.id}
        disableColumnMenu
        disableColumnResize
        autoHeight
        onSelectionModelChange={(newModel) => {
          onSelectionChange(newModel);
        }}
      />
      <UserActivityDialog
        user={userActivity}
        open={userActivity !== null}
        onClose={hideUserActivity}
      />
    </>
  );
};

export default UsersTable;
