import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";

import { PageTitle } from "../../components";
import { StyledLink } from "../../styles/styles";

const GroupsList = ({ groups }) => (
  <List>
    {groups.map((group) => (
      <ListItem key={uuidv4()}>
        <StyledLink to={`/group/${group.id}`}>
          <ListItemText primary={group.title} secondary={group.description} />
        </StyledLink>
      </ListItem>
    ))}
  </List>
);

const NoGroups = () => (
  <Typography variant="h4" component="p" gutterBottom>
    No groups found
  </Typography>
);

const Groups = ({ groups }) => (
  <Grid container>
    <Grid item xs={12}>
      <PageTitle variant="h4" component="h2">
        Groups Page
      </PageTitle>
      {isEmpty(groups) ? <NoGroups /> : <GroupsList groups={groups} />}
    </Grid>
  </Grid>
);

export default Groups;
