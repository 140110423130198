import React from "react";
import { Loading } from "../../components";
import { useGroups } from "../../hooks";
import Groups from "./Groups";

const GroupsPage = () => {
  const { groups, loading, error } = useGroups();

  if (loading) return <Loading label="groups" />;
  if (error) return `Error! ${error.message}`;

  return <Groups groups={groups} />;
};

export default GroupsPage;
