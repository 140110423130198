const PEAR_APP_DOMAINS = [
  "https://staging.user-management.halasystems.com",
  "https://user-management.halasystems.com",
];

const getCurrentOrigin = () => {
  const { origin } = window.location.origin;
  return origin;
};

const isHalaDomain = () => {
  const origin = getCurrentOrigin();
  return PEAR_APP_DOMAINS.includes(origin);
};

export default isHalaDomain;
