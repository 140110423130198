import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Box,
} from "@mui/material";
import GroupSelection from "./GroupSelection";

const EmailInput = ({ email, onChange, isValid }) => (
  <TextField
    margin="dense"
    id="user-email"
    label="Email"
    type="email"
    sx={{ mb: 3 }}
    onChange={onChange}
    fullWidth
    value={email}
    error={!isValid && email.length > 0}
  />
);

const ActionButtons = ({ onClose, onConfirmation, disableConfirm }) => (
  <>
    <Button onClick={onClose}>Cancel</Button>
    <Button onClick={onConfirmation} disabled={disableConfirm}>
      ADD NEW USER
    </Button>
  </>
);

const DialogLayout = ({ children, open, onClose, actions }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Add a new user</DialogTitle>
    <DialogContent>
      <Box component="form">{children}</Box>
    </DialogContent>
    <DialogActions>{actions}</DialogActions>
  </Dialog>
);

const DialogForm = ({ open, onClose, groups, onAddUser }) => {
  const [group, setGroup] = useState("");
  const [email, setEmail] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);

  const isValidEmail = /(.+)@(.+){2,}\.(.+){2,}/.test(email);

  const handleChange = (groupId) => {
    setGroup(groupId);
    setBtnDisabled(email.length === 0 || groupId.length === 0);
  };

  const handleChangeTextfield = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setBtnDisabled(newEmail.length === 0 || group.length === 0);
  };

  const handleConfirmation = () => {
    onAddUser(email, group);
    setEmail("");
    setGroup("");
  };

  return (
    <DialogLayout
      open={open}
      onClose={onClose}
      actions={
        <ActionButtons
          onClose={onClose}
          onConfirmation={handleConfirmation}
          disableConfirm={btnDisabled || !isValidEmail}
        />
      }
    >
      <EmailInput
        onChange={handleChangeTextfield}
        email={email}
        isValid={isValidEmail}
      />
      <GroupSelection onChange={handleChange} groups={groups} group={group} />
    </DialogLayout>
  );
};

export default DialogForm;
