import React from "react";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import ActivityDetails from "./ActivityDetails";

const closeStyle = {
  position: "absolute",
  right: 8,
  top: 8,
  color: "#9e9e9e",
};

const UserActivityDialog = ({ user, open, onClose }) => (
  <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
    {user ? (
      <>
        <DialogTitle fontWeight={600}>
          {user.email}
          <IconButton aria-label="close" onClick={onClose} sx={closeStyle}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <ActivityDetails user={user} />
        </DialogContent>
      </>
    ) : null}
  </Dialog>
);

export default UserActivityDialog;
