import React from "react";
import { useAuthUser } from "@hala-systems/hala-auth";
import { Box, Typography } from "@mui/material";
import { userHasPermission } from "../permissions";
import { LogoutButton } from "../../components";

const NotAuthorizedMessage = () => (
  <Box
    sx={{
      p: 6,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      width: "100%",
    }}
  >
    <Typography variant="h1" component="h1" gutterBottom>
      Unauthorized
    </Typography>

    <Typography variant="body1" component="p" gutterBottom>
      Sorry, you don&apos;t currently have access to this page.
    </Typography>

    <Typography variant="body1" component="p" gutterBottom>
      Please reach out to{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="mailto:nebojsa@halasystems.com?subject=Access to User Management App &body=Please give this email access to the overlay app"
      >
        nebojsa@halasystems.com
      </a>{" "}
      to get access
    </Typography>

    <Box>
      <LogoutButton variant="contained" />
    </Box>
  </Box>
);

const PermissionControl = ({ children }) => {
  const { user } = useAuthUser();

  const hasPermission = userHasPermission(user, "ACCESS_COLLECT");

  return hasPermission ? children : <NotAuthorizedMessage />;
};

export default PermissionControl;
