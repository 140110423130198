import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Auth, Hub } from "aws-amplify";

const StyledButton = styled(Button)(() => ({
  textTransform: "none",
  width: "100%",
  justifyContent: "flex-start",
}));

const logout = async () => {
  await Auth.signOut({ global: true });
  Hub.dispatch("UI Auth", {
    event: "AuthStateChange",
    message: "signedout",
  });
};

const LogoutButton = ({ variant }) => (
  <StyledButton size="large" onClick={logout} variant={variant} role="button">
    Log out
  </StyledButton>
);

export default LogoutButton;
