import React from "react";

import { Box, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { PageTitle } from "../../../components";

const boxStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const ActionTitle = ({ groupName, disableRemoveUser, onRemoveClick }) => (
  <Box sx={boxStyles}>
    <PageTitle variant="h4" component="h2">
      {groupName}
    </PageTitle>
    <Button
      variant="outlined"
      disabled={disableRemoveUser}
      onClick={onRemoveClick}
      startIcon={<DeleteIcon />}
    >
      Remove Users
    </Button>
  </Box>
);

export default ActionTitle;
