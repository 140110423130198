import { useQuery } from "@apollo/client";
import { USERS } from "../queries";

const getUserData = (data) => data?.users.edges.map(({ node }) => node);

const useUsers = (groupId) => {
  const { data, loading, error } = useQuery(USERS, { variables: { groupId } });

  return { loading, error, users: getUserData(data) };
};

export default useUsers;
