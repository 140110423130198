import { Auth } from "aws-amplify";

/**
 * Extract the permissions array from
 * the current user's idToken
 */
export const getUserPermissions = (sessionUser) => {
  const user = sessionUser ?? Auth.user;
  const permissions = user?.signInUserSession?.idToken?.payload?.permissions;
  return permissions ? permissions.split(",") : [];
};

export const userHasPermission = (user, permission) =>
  getUserPermissions(user).includes(permission);
