import React from "react";
import { v4 as uuidv4 } from "uuid";
import { FormControl, InputLabel, Select, OutlinedInput } from "@mui/material";

const GroupSelection = ({ group, groups, onChange }) => (
  <FormControl fullWidth>
    <InputLabel htmlFor="group-select-box">Groups</InputLabel>
    <Select
      id="group-select-box"
      margin="dense"
      sx={{ mb: 3 }}
      native
      value={group}
      onChange={(event) => {
        onChange(event.target.value);
      }}
      input={<OutlinedInput label="Groups" id="group-select-box" />}
      fullWidth
    >
      <option aria-label="None" value="" />
      {groups.map((g) => (
        <option key={uuidv4()} value={g.id}>
          {g.id}
        </option>
      ))}
    </Select>
  </FormControl>
);

export default GroupSelection;
