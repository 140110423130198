import { useQuery } from "@apollo/client";
import { GROUPS } from "../queries";

const getGroupData = (data) => data?.groups.edges.map(({ node }) => node);

const useGroups = () => {
  const { data, loading, error } = useQuery(GROUPS);

  return { loading, error, groups: getGroupData(data) };
};

export default useGroups;
