import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import AddNewUserButton from "../AddNewUserButton/AddNewUserButton";

const zIndex = (theme) => theme.zIndex.drawer + 1;

const TopToolBar = () => (
  <AppBar position="fixed" sx={{ zIndex }}>
    <Toolbar>
      <Typography variant="h6" component="h1" noWrap flex="1">
        User Management
      </Typography>
      <AddNewUserButton />
    </Toolbar>
  </AppBar>
);

export default TopToolBar;
