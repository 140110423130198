import { gql } from "@apollo/client";

const GROUPS = gql`
  query allGroups($first: Int, $after: String) {
    groups(first: $first, after: $after) {
      edges {
        node {
          id
          title
          description
        }
      }
    }
  }
`;

export default GROUPS;
