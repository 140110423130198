import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const RemoveConfirmationDialog = ({
  open = false,
  count,
  groupName,
  onClose,
  onConfirm,
}) => {
  const userMsg = `${count} user${count > 1 ? "s" : ""}`;
  const msg = `Are you sure you want to remove ${userMsg} from ${groupName}?`;
  const remove = `Remove ${userMsg}`;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {open ? (
        <>
          <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {msg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onConfirm} autoFocus color="error">
              {remove}
            </Button>
          </DialogActions>
        </>
      ) : null}
    </Dialog>
  );
};

export default RemoveConfirmationDialog;
