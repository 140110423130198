import React from "react";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const LoadingWrapper = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textAlign: "center",
}));

const Loading = ({ label }) => (
  <LoadingWrapper>
    Loading {label}... <CircularProgress />
  </LoadingWrapper>
);

export default Loading;
