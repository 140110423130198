import React, { useState } from "react";

import { Button, Snackbar } from "@mui/material";
import { useGroups } from "../../hooks";
import DialogForm from "./DialogForm";

const anchorOrigin = { vertical: "bottom", horizontal: "right" };

const ConfirmationMessage = ({ open, onClose, email, group }) => (
  <Snackbar
    anchorOrigin={anchorOrigin}
    open={open}
    onClose={onClose}
    autoHideDuration={3000}
    message={`${email} was given access to ${group}`}
    key="bottomright"
  />
);

const initialState = {
  isSnackbarOpen: false,
  isDialogOpen: false,
  email: "",
  group: "",
};

const AddNewUserButton = () => {
  const { loading, groups, error } = useGroups();

  const [state, setState] = useState(initialState);

  const openDialog = () => setState({ ...state, isDialogOpen: true });

  const closeDialog = () => setState({ ...state, isDialogOpen: false });

  const closeSnackBar = () => setState({ ...state, isSnackbarOpen: false });

  const addNewUser = (email, group) => {
    setState({
      isDialogOpen: false,
      isSnackbarOpen: true,
      email,
      group,
    });
  };

  return (
    <>
      <Button
        color="inherit"
        onClick={openDialog}
        disabled={!!error || loading}
      >
        ADD NEW USER
      </Button>

      <DialogForm
        open={state.isDialogOpen}
        onClose={closeDialog}
        groups={groups}
        onAddUser={addNewUser}
      />

      <ConfirmationMessage
        open={state.isSnackbarOpen}
        onClose={closeSnackBar}
        email={state.email}
        group={state.group}
      />
    </>
  );
};

export default AddNewUserButton;
