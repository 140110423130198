import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const PageTitle = styled(Typography)(({ theme }) => ({
  display: "block",
  color: theme.palette.secondary.dark,
  fontSize: 24,
  padding: "0 20px",
  margin: "20px 0",
}));

export default PageTitle;
