import React from "react";
import {
  HoneycombThemeProvider,
  SENTRY_THEME_RESPONSIVE,
} from "@hala-systems/honeycomb";
import { BrowserRouter as Router } from "react-router-dom";
import { Toolbar as Spacer } from "@mui/material";
import "./index.css";
import "toastr/build/toastr.min.css";
import { PermissionControl, UserManagementAuthenticator } from "./auth";
import { Layout, UserManagementProvider } from "./components";
import Routes from "./Routes";

const AppProviders = ({ children }) => (
  <UserManagementProvider>
    <HoneycombThemeProvider theme={SENTRY_THEME_RESPONSIVE}>
      <UserManagementAuthenticator>
        <PermissionControl>
          <Router>{children}</Router>
        </PermissionControl>
      </UserManagementAuthenticator>
    </HoneycombThemeProvider>
  </UserManagementProvider>
);

const App = () => (
  <AppProviders>
    <Layout>
      <Spacer />
      <Routes />
    </Layout>
  </AppProviders>
);

export default App;
